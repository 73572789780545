@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-gray-sm {
    @apply bg-gray-500 py-1 px-4 text-sm text-white rounded-sm shadow-sm hover:opacity-90;
  }
}

.kird-admin h1 {
  @apply text-2xl font-bold;
}

.kird-admin h2 {
  @apply text-2xl font-semibold;
}

caption {
  font-size: 0;
  padding: 0; /* Home > MY > 나의일정 - 캘린더 caption에 padding을 제어하기 위해 추가 230623 */
}

a {
  color: inherit;
  text-decoration: none;
}

.kirdContainer {
  background: #ffffff;
}

/** override slick-carousel **/
.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  color: black;
}

.kird-admin table.list-table th {
  @apply py-2 px-4 bg-gray-200 font-medium;
}
.kird-admin table.list-table td {
  @apply py-2 px-4;
}
.kird-admin table.list-table tr {
  @apply border-b;
}

/** /////////////////////////////////// **/
/* published style */
/** /////////////////////////////////// **/

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("/webfont/Rubik-Regular.woff") format("woff"),
    url("/webfont/Rubik-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("/webfont/Rubik-Medium.woff") format("woff"),
    url("/webfont/Rubik-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("/webfont/Rubik-Bold.woff") format("woff"),
    url("/webfont/Rubik-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Spartan";
  font-style: normal;
  font-weight: 700;
  src: url("/webfont/Spartan-Bold.woff2") format("woff2");
}

/* 본고딕 Light */
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 200;
  src: url("/webfont/NotoSansKR-Light.woff") format("woff"),
    url("/webfont/NotoSansKR-Light.woff2") format("woff2"),
    url("/webfont/NotoSansKR-Light.otf") format("opentype");
}
/* 본고딕 Regular */
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 300;
  src: url("/webfont/NotoSansKR-Regular.woff") format("woff"),
    url("/webfont/NotoSansKR-Regular.woff2") format("woff2"),
    url("/webfont/NotoSansKR-Regular.otf") format("opentype");
}
/* 본고딕 Medium */
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 400;
  src: url("/webfont/NotoSansKR-Medium.woff") format("woff"),
    url("/webfont/NotoSansKR-Medium.woff2") format("woff2"),
    url("/webfont/NotoSansKR-Medium.otf") format("opentype");
}
/* 본고딕 Bold */
@font-face {
  font-family: "NotoSansCJKkr";
  font-style: normal;
  font-weight: 700;
  src: url("/webfont/NotoSansKR-Bold.woff") format("woff"),
    url("/webfont/NotoSansKR-Bold.woff2") format("woff2"),
    url("/webfont/NotoSansKR-Bold.otf") format("opentype");
}

/** default publish style **/
dl,
ul,
ol,
menu,
li {
  list-style: none;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
}
input[type="checkbox"] {
  font-size: 0;
  opacity: 0.01;
  overflow: hidden;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #fff url(/images/icon_select.png) calc(100% - 9px) center
    no-repeat;
}
select::-ms-expand {
  display: none;
}

/* ----------------------------------- 버튼 ----------------------------------- */
/* 버튼 - 공통 */
.btn {
}
.btn.blue {
  color: #ffffff;
  background: #3c89f9;
}
.btn.grey {
  color: #7f7f7f;
  background: #e5e5e5;
}
.btn.dark {
  color: #ffffff;
  background: #7f7f7f;
}
.btn.line {
  color: #3c89f9;
  background: #ffffff;
  border: 1px solid #3c89f9;
}
.btn.lg {
  height: 64px;
  margin-left: 10px;
  padding: 0 87.5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.78;
  letter-spacing: -0.38px;
  text-align: center;
}
.btn.lg:first-child {
  margin-left: 0;
}
.btn.alert {
  width: 130px;
  height: 40px;
  margin-right: 10px;
  font-size: 15px;
  line-height: 1.33;
}
.btn.alert:last-child {
  margin-right: 0;
}
@media screen and (min-width: 100px) and (max-width: 1279px) {
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .btn.lg {
    width: 100%;
    height: 50px;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
  }
  .btn.alert {
    width: auto;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  .btn.alert.blue {
    background: none;
    color: #3c89f9;
  }
  .btn.alert.grey {
    background: none;
    color: #7f7f7f;
  }
  .btn.signUphalf {
    width: calc(50% - 2.5px);
  }
  .mTwo > .btn {
    width: calc(50% - 5px);
    padding: 0;
  }
  .mThr > .btn {
    width: calc(33.333% - 6.666px);
    padding: 0;
  }
}
.btn.signUphalf {
  width: calc(50% - 5px);
}

/* 버튼 그룹 */
.comBtnArea {
  width: 100%;
}
.comBtnArea .btnArea {
  width: 100%;
  max-width: 1384px;
  margin: 0 auto;
}
.comBtnArea .btnArea:after {
  content: "";
  display: block;
  clear: both;
}
.comBtnArea .alignArea {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  /* font-size: 0; */
  /* text-align: center; */
}
.comBtnArea .alignArea.left {
  text-align: left;
  float: left;
}
.comBtnArea .alignArea.center {
  float: left;
}
.comBtnArea .alignArea.right {
  text-align: right;
  float: right;
}

@media screen and (min-width: 100px) and (max-width: 1279px) {
}
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .btnArea.mIn {
    padding: 0 20px;
  }
}

/* 버튼 - 로그인 */
.btn.login {
  width: 255px;
  height: 50px;
  /* margin-left: 10px; */
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}
/* .btn.login:first-child {
  margin-left: 0;
} */
@media screen and (min-width: 100px) and (max-width: 1023px) {
  .btn.login {
    width: 100%;
    /* margin-left: 0; */
    font-size: 14px;
  }
  .mTwo .btn.login {
    width: calc(50% - 2.5px);
    /* margin-left: 5px; */
  }
  /* .mTwo .btn.login:first-child {
    margin-left: 0;
  } */
}

/*버튼 - kird 스페셜 수강신청*/
.btn.apply {
  width: 100px;
  height: 45px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
}

/* 콘텐츠 내 검색박스 */
.comSearchInCont {
  width: 100%;
  height: 78px;
  padding-top: 19px;
  font-size: 0;
  text-align: center;
  background: #f2f2f2;
}
.comSearchInCont .searchArea {
  width: 990px;
  display: inline-block;
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .comSearchInCont {
    width: 100%;
    height: 48px;
    padding: 12px 16px;
  }
  .comSearchInCont .searchArea {
    width: 100%;
  }
}

/* search bar */
.formSearchBar {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.formSearchBar input {
  width: 100%;
  height: 42px;
  border: none;
  border-bottom: 2px solid #000;
  font-size: 18px;
  color: #7c7c7c;
}
.formSearchBar .btnSearch {
  width: 24px;
  height: 24px;
  font-size: 0;
  background: url(/images/icon/ico-24-search.svg) center no-repeat;
  position: absolute;
  right: 0;
  top: 9px;
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .formSearchSelect {
    font-size: 14px;
  }

  .formSearchBar {
    width: 100%;
  }
  .formSearchBar input {
    width: 100%;
    height: 48px;
    padding-left: 16px;
    border-bottom: none;
    font-size: 14px;
    background: #f2f2f2;
  }
  .formSearchBar .btnSearch {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

/** /////////////////////////////////// **/
/* PageChange Components */
/** /////////////////////////////////// **/
body-page-transition {
  overflow: hidden;
}
.body-page-transition #page-transition {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.body-page-transition #page-transition > div:after {
  content: "";
  /*background-image: url("assets7.jpg");*/
  background-size: cover;
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.body-page-transition #page-transition > div:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  z-index: 9999;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
}

.popupContGroup {
  height: calc(100% - 105px);
  padding: 40px;
  overflow: auto;
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .popupContGroup {
    height: calc(100% - 60px);
    padding: 30px 20px;
    overflow: auto;
  }
}

/* 본문바로가기  */
.accessibility {
  position: relative;
  overflow: hidden;
  z-index: 3000;
  width: 100%;
}
.accessibility a {
  display: block;
  overflow: hidden;
  z-index: 3000;
  height: 1px;
  width: 1px;
  margin: 0 -1px -1px 0;
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.accessibility a:focus,
#accessibility a:hover,
#accessibility a:active {
  width: auto;
  height: 30px;
  margin: 0;
  padding: 8px 0;
  background: #20262c;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}

.cptProcessCard button {
  width: 100%;
  text-align: left;
}

.thumTitle button {
  width: 100%;
}

/* 도움말 팝업 css */
.helpModalContents {
  padding: 0 350px 70px 70px;
  background: #ebf3ff url("/images/common/right-img.svg") no-repeat right 50px
    bottom -30px;
  background-size: 268px auto;
}
.helpModalInner {
  padding: 20px 50px;
  background-color: #f6f6f6;
  line-height: 1.5;
}
.helpModalContents h3 {
  font-size: 1rem;
  font-weight: bold;
  word-break: keep-all;
}
.helpModalContents dl {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0 5px;
  font-size: 0.875rem;
  word-break: keep-all;
}
.helpModalContents dl dt {
  font-weight: bold;
}
.helpModalContents h4 {
  font-weight: bold;
}
.helpModalContents h4,
.helpModalContents p,
.helpModalContents li {
  font-size: 0.875rem;
  word-break: keep-all;
}
.helpModalContents .noti {
  font-size: 0.75rem;
}

@media (max-width: 960px) {
  .helpModalContents {
    padding: 0;
    background: none;
  }
  .helpModalInner {
    margin-top: 20px;
    padding: 20px;
  }
}

/* 팝업 기본 css 240625 */
.modalInner {
  padding: 2.5rem;
}

@media screen and (min-width: 100px) and (max-width: 1023px) {
  .modalInner {
    margin-top: 30px;
    padding: 0;
  }
}
